import React from 'react';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/ksa/ksa-broadcast-pro-summit-event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/ksa/ksa-broadcast-pro-summit-event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/ksa/ksa-broadcast-pro-summit-event-og.jpg';

export default function BroadcastProSummitKSA2025() {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='BroadcastPro Summit KSA 2025' isEvents>
            <SEO
                title='BroadcastPro Summit KSA 2025'
                ogDescription='Let’s meet at BroadcastPro Summit KSA in Riyadh and experience a new way of monitoring and testing video services.'
                description='Let’s meet at BroadcastPro Summit KSA in Riyadh and experience a new way of monitoring and testing video services.'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={19} banner={mobileBanner} /> : <DesktopEventPage id={19} banner={banner} />}
        </Layout>
    )
}
